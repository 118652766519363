/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  // Fetch
  fetchItems ({ commit }, payload) {
    let params = {};

    if (payload) {
      if (payload.params)
        params = payload.params;
    }

    return new Promise((resolve, reject) => {
      axios.get('/invoice-customers', { params })
        .then((response) => {
          commit('SET_ITEMS', response.data.values)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  exportItems ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/invoice-customers/export/${payload.start_date}/${payload.end_date}/excel`, {  })
        .then((response) => {
          commit('SET_ITEM', response.data.values)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  detailItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/invoice-customers/${payload.id}`, {  })
        .then((response) => {
          commit('SET_ITEM', response.data.values)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/invoice-customers', payload.body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/invoice-customers/${payload.id}`, payload.body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
        }
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/invoice-customers/${payload}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  printInvoice({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/invoice-customers/${payload.id}/print-invoice`, {
        selected: JSON.stringify(payload.selected),
        payment_tempo: payload.payment_tempo,
      }, {
          responseType: 'blob',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*",
          }
      })
      .then((response) => {
        // const htmlContent = response.data;

        // // Open a new tab
        // const newTab = window.open('', '_blank');

        // // Write the HTML content into the new tab
        // newTab.document.write(htmlContent);

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const filename = payload.invoice_number.replace(/\//g, '-'); // This replaces all '/' with '-'

        // Create a download link for the PDF
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice-' + filename + '.pdf'); // Use the dynamic filename
        link.click()
        resolve(response)
      })
      .catch((error) => { reject(error) });
    })
  }

}
